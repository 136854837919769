/**
 * TEMPLATE-OPTIONS.MODEL.TS
 * Options for the template
 */

export class TemplateOptions{
  emojiMode:boolean = false
  responseEmojiLabel_no_fr:string = ""
  responseEmojiLabel_yes_fr:string = ""
  showCircle: boolean = false
  triggerNegativeAnswer : number = 50 //Trigger to display question
  triggerPositiveAnswer : number = 50 //Trigger to display question
  constructor(item: Partial<TemplateOptions> = {}){
    Object.assign(this, item)
  }
}